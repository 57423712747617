<template>
    <Transition name="modal">
        <div
            :class="{modal: true, 'blur-background': blurBg}"
            :style="style"
            v-if="isActive"
            @click.self="$emit('close')"
            @keydown.esc="$emit('close')"
        >
            <div :class="props.contentClass" class="content">
                <slot />
            </div>
        </div>
    </Transition>
</template>

<script setup>
import { watch } from 'vue';

defineEmits(['close']);

const props = defineProps({
    style: {
        type: Object,
        required: false,
        default: () => {},
    },
    blurBg: {
        type: Boolean,
        required: false,
        default: false,
    },
    contentClass: {
        type: [Object, String],
        required: false,
        default: '',
    },
    isActive: {
        type: Boolean,
        required: false,
        default: false,
    },
});

watch(
    () => props.contentClass,
    () => null,
    {
        immediate: true,
    },
);
</script>

<style lang="scss" scoped>
.modal {
    background: rgba(0,0,0,0.6);
    position: fixed;
    inset: 0;
    z-index: 50;
    display: grid;
    justify-content: center;
    align-items: center;
    padding: 50px;
    transition: 0.2s ease-in-out opacity;

    &.blur-background {
        backdrop-filter: blur(8px);
    }

    & .content {
        width: calc(100vw - 50px);
        max-width: 800px;
        max-height: calc(100vh - 50px);
        background: #f5f7fb;
        border-radius: 4px;
        overflow: auto;
        transition: 0.2s ease-in-out transform;

        &.waiting {
            cursor: wait;
            opacity: 0.8;
        }
    }

    & .content.cw1000 {
        max-width: 1000px;
    }
    & .content.cw380 {
        max-width: 380px;
    }
}

.modal-enter-from {
    opacity: 0;
}

.modal-leave-to {
    opacity: 0;
}

.modal-enter-from .content,
.modal-leave-to .content {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
}
</style>
