<template>
    <div>
        <Multiselect
            :filter="filter"
            :value="modelValue"
            value-prop="value"
            track-by="value"
            :object="true"
            :can-clear="false"
            :mode="mode"
            :infinite="true"
            :placeholder="placeholder"
            :noOptionsText="noOptionsText"
            :noResultsText="noResultsText"
            :appendNewTag="false"
            :close-on-select="!multiple"
            :filter-results="false"
            :min-chars="minChars.valueOf()"
            :resolve-on-load="true"
            :createTag="false"
            :createOption="false"
            :delay="0"
            :disabled="disabled"
            :multiple="multiple"
            :searchable="searchable"
            :limit="limit"
            :clear-on-search="true"
            ref="multiselect"
            :options="ajax ? handleSearch : handleOptions"
            @select="onSelect"
            @deselect="onDeselect"
            @search-change="ajax ? handleSearch : () => {}"
            :class="horizontal?'horizontal':'vertical'"
        >
            <template v-slot:option="slotProps">
                <div :class="{flex: true,  'items-center': true, inactive: !(slotProps.option.is_active ?? true)}">
                    <span v-if="slotProps.option.language" :class="'mr-2 fi fi-' + mapLanguageFlags(slotProps.option.language)"></span>
                    <span>{{ slotProps.option.label }}</span>
                </div>
            </template>
            <!-- inherit it from caller and pass through to Multiselect / overwrite the default slot options above -->
            <template v-for="(_, name) in slots" v-slot:[name]="slotData">
                <slot :name="name" v-bind="slotData" />
            </template>

            <template v-slot:tag="{ option, handleTagRemove }">
                <div :class="{item: true, inactive: !(option.is_active ?? true), first: handleOrder && isFirst(option)}">
                    <span v-if="option.language" :class="'mr-2 fi fi-' + mapLanguageFlags(option.language)"></span>
                    <span>{{ option.label }}</span>
                    <span v-if="handleOrder && !isFirst(option)"
                          @click.prevent
                          v-tooltip="trans('user.edit.form.teams.first')"
                          @mousedown.prevent.stop="handleTagSetFirst(option)"
                          class="mdi mdi-12 mdi-star">
                    </span>
                    <span v-for="action in actions"
                          v-bind:key="action.action"
                          @click.prevent
                          v-tooltip="action.tooltip ?? null" 
                          @mousedown.prevent.stop="actionParams = action.params, handleTagRemove(option, $event)"
                          :class="'mdi mdi-12 mdi-' + action.icon">
                    </span>
                </div>
            </template>
        </Multiselect>
    </div>
</template>

<script setup>
import { watch, ref, useSlots } from 'vue';
import { trans } from 'matice';
import { handleErrors, mapLanguageFlags } from '@/integeri-helper.js';
import Multiselect from '@vueform/multiselect';
import axios from 'axios';

const slots = useSlots();
// inform parent
const emit = defineEmits(['update:modelValue', 'update:modelValue:select', 'update:modelValue:deselect']);

const props = defineProps({
    modelValue: {
        type: Array,
        default: () => [],
    },
    options: {
        type: Array,
        default: () => [],
    },
    users: {
        type: Array,
        default: () => [],
    },
    location: {
        type: Object,
        default: null,
    },
    team_id: {
        type: Number,
        default: null,
    },
    assigneeType: {
        type: String,
        default: '',
    },
    scope: {
        type: String,
        default: '',
    },
    actions: {
        type: Array,
        required: false,
        default: () => [{action: 'remove', icon: 'trash-can'}],
    },
    routeMe: {
        type: String,
        default: 'ajax.user.find',
    },
    mode: {
        type: String,
        default: 'tags',
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    multiple: {
        type: Boolean,
        default: true,
    },
    limit: {
        type: Number,
        default: 50,
    },
    searchable: {
        type: Boolean,
        default: true,
    },
    filter: {
        type: String,
        default: () => {},
    },
    placeholder: {
        type: String,
        default: trans('global.select_user'),
    },
    horizontal: {
        type: Boolean,
        default: true,
    },
    noOptionsText: {
        type: String,
        default: trans('global.please_search'),
    },
    noResultsText: {
        type: String,
        default: trans('global.please_search'),
    },
    ajax: {
        type: Boolean,
        default: true,
    },
    company_id: {
        type: Number,
        required: false,
        default: null,
    },
    handleOrder: {
        type: Boolean,
        required: false,
        default: false,
    },
});

const selectedUsers = ref([]);
// global minimum char before search
const minChars = ref(2);
// initial given values of model
const initialValues = props.modelValue.valueOf();
const multiselect = ref(null);
const actionParams = ref([]);

watch(
    () => props.modelValue,
    (nVal) => {
        selectedUsers.value = nVal;
    },
    {
        immediate: true,
    },
);

const isFirst = (option) => {
    return (option.value == ((selectedUsers.value?.length??0) ? selectedUsers.value[0].value : -1));
};

const _evalOptionIndex = (option) => {
    let found = -1;
    selectedUsers.value.forEach(function(item, i) {
        if (item.value === option.value) {
            found = i;
            return false;
        }
        if (item.id === option.value) {
            found = i;
            return false;
        }
    });
    return found;
};

const handleTagSetFirst = (option) => {
    let found = _evalOptionIndex(option);
    if (found !== -1) {
        let search = selectedUsers.value.splice(found, 1);
        selectedUsers.value.unshift(search[0]);
    }
};

// only: ajax: handles the search initial and after typing
const handleSearch = async (query) => {
    if (query === null || query === '' || query.length < minChars.value) {
        return new Promise((resolve) => {
            resolve(initialValues);
        });
    } else {
        let data = {'scope': props.scope, 'search': query};
        if (props.team_id) {
            data.team = props.team_id;
        }
        if (props.company_id){
            data.company = props.company_id;
        }
        return axios.get(route(props.routeMe, data))
            .then(function(response) {
                if (query == '**') {
                    multiselect.value.clearSearch();
                } 
                return initialValues.concat(response.data);
            })
            .catch((e) => {
                handleErrors(e);
                initialValues.value = {'label': trans('error.E503'), 'value': ''};
            })
            .finally(() => {
            });
    }
};

const handleOptions = (query) => {
    return new Promise((resolve) => {
        if(!query) {
            resolve(props.options);
        }
        else {
            const filtered = props.options.filter((option) => {
                return option.label.toLowerCase().indexOf(query.toLowerCase()) >= 0;
            });
            resolve(filtered);
        }
    });
};

// event after select an option
const onSelect = (option, obj) => {
    // on single empty current
    if (!props.multiple) {
        selectedUsers.value = [];
    }
    selectedUsers.value.push(obj);
    let found = _evalOptionIndex(option);
    if (found !== -1) {
        emit('update:modelValue:select', [selectedUsers.value[found], props.scope, ...actionParams?.value??[]]);
    }
    // inform listener
    emit('update:modelValue', selectedUsers.value);
};

// event after deselect
const onDeselect = (option) => {
    let found = _evalOptionIndex(option);
    if (found !== -1) {
        emit('update:modelValue:deselect', [selectedUsers.value[found], props.scope, ...actionParams?.value??[]]);
        selectedUsers.value.splice(found, 1);
        // inform listener
        emit('update:modelValue', selectedUsers.value);
    }
};
</script>

<!-- integeri adopted multiselect styles -->
<style src="@/../css/integeri-multiselect.css"></style>
<style lang="scss">
.multiselect-tags-search-wrapper {
    margin-bottom: 8px;
    margin-top: 8px;
}
.multiselect .multiselect-tags-search {
    margin-top: -10px;
    margin-left: -6px;
    border: 0px !important;
    height: 36px !important;
    line-height: 1;
}
.multiselect-option:has(.inactive) {
    background-color: var(--app-color-danger-background);
}
.multiselect-option.is-selected,
.multiselect-option.is-selected.is-pointed {
    background-color: var(--app-color-blue-background);
    color: var(--app-color-blue);    
}
.multiselect-tags {
    margin: 0px;
    & div.item {
        background: var(--app-color-blue-background);
        color: var(--app-color-blue);
        margin-right: 5px;
        padding: 8px;
        border-radius: 8px;
    
        & span:nth-child(3) {
            margin-left: 4px;
        }
        &.first {
            background: var(--app-color-yellow-light);
        }
        &.inactive {
            background-color: var(--app-color-danger-background);
        }
    }
}
</style>