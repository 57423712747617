import './bootstrap';

import '@mdi/font/scss/materialdesignicons.scss';
import '@fontsource/work-sans/variable.css';
import '../css/reset.scss';
import '../css/app.scss';
import '../css/flag-icons.scss';
import '../theme/styles.scss';

import { createApp, h } from 'vue';
import { RouterLink } from 'vue-router';
import { createInertiaApp, router } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import mitt from 'mitt';
import { __, trans, setLocale, getLocale, transChoice, locales } from 'matice';
import IComponents from '@/Components/Common/IComponents';
import AppLayout from './App.vue';
import { Vue3Mq } from 'vue3-mq';
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';
import Tooltip from 'primevue/tooltip';
import { toastBus } from '@/toast.js';
import * as Sentry from '@sentry/vue';

// dynamically load locale for primevue
// TODO check nb-no, zh-CN, en, et, lt
let pvlang = {};
const mapPrimeLocales = (locale) => {
    let map = {et: 'en', lt: 'en'};
    return map[locale] || locale;
};
const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Integeri';
/* Detected back button and reload course step on back click */
let popState = false;
window.addEventListener('popstate', () => {
    popState = true;
});
router.on('navigate', (event) => {
    const page = event.detail.page;
    if (page.component === 'Authenticated/CoursePlayer/Step' && popState) {
        router.get(page.url, {}, { replace: true, preserveState: false });
    }
    popState = false;
});

const app = createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => {
        const page = resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob('./Pages/**/*.vue'),
        );

        page.then((module) => {
            module.default.layout = module.default.layout || AppLayout;
        });

        return page;
    },
    async setup({ el, App, props, plugin }) {
        // deactivate google analytics for video.js
        window.HELP_IMPROVE_VIDEOJS = false;

        const ilocale = mapPrimeLocales(getLocale());
        const mlocale = await import(`./../../node_modules/primelocale/${ilocale}.json`);
        pvlang[ilocale] =  mlocale[ilocale];
        
        const Vue = createApp({ render: () => h(App, props) });

        Vue.provide('emitter', new mitt());
        Vue.provide('toastBus', toastBus);
        Vue.mixin({
            methods: {
                trans: trans,
                __: __,
                transChoice: transChoice,
                setLocale(locale) {
                    setLocale(locale);
                    this.$forceUpdate();
                },
                currentLocale() {
                    return getLocale();
                },
                availableLocales() {
                    return locales();
                },
            },
        });

        Vue.use(plugin);
        // eslint-disable-next-line no-undef
        Vue.use(ZiggyVue, Ziggy);
        Vue.use(IComponents);
        Vue.use(Vue3Mq);
        Vue.use(PrimeVue, {
            ripple: true,
            inputVariant: 'filled',
            locale: Object.assign({}, pvlang[ilocale]),
        });
        Vue.use(ToastService);
        Vue.use(ConfirmationService);
        Vue.directive('tooltip', Tooltip);
        Vue.component('router-link', RouterLink);
        Vue.config.errorHandler = (err, instance, info) => {
            console.log('integeri-error-handler', err, instance, info);
            // handle error, e.g. report to a service
            throw new Error(err + ' # ' + info);
        };
        return Vue.mount(el);
    },
    // TODO check performance and UI
    progress: true,
});
// eslint-disable-next-line no-undef
Sentry.init({
    app,
    dsn: import.meta.env.VUE_APP_SENTRY_VUE_DSN,
    // dsn: 'https://bfb167fe4a8e4183b0bbe7b96a4205a8@o4504950287237120.ingest.us.sentry.io/4505338125942784',
    integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    // Learn more at
    // https://docs.sentry.io/platforms/javascript/configuration/options/#traces-sample-rate
    tracesSampleRate: 1.0,
  
    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    // eslint-disable-next-line no-undef
    tracePropagationTargets: [import.meta.env.VUE_APP_SENTRY_VUE_TARGET],
  
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    // Learn more at
    // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});
