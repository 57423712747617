<template>
    <Button
        :id="id"
        tabindex="0"
        type="button"
        :class="buildClass"
        :disabled="disabled"
        @click.stop="click"
        v-tooltip
    >
        <span
            v-if="icon && !loading"
            :class="`mdi mdi-${icon}`"
        />
        <div
            v-if="loading"
            class="mdi mdi-loading loading"
        />
        <span
            v-if="label"
            class="label"
        >
            {{ label }}
        </span>
    </Button>
</template>

<script setup>
import { computed } from 'vue';
const emits = defineEmits(['click']);
import Button from 'primevue/button';

const props = defineProps({
    id: {
        type: String,
        required: false,
        default: '',
    },
    icon: {
        type: [String, Boolean],
        required: false,
        default: false,
    },
    label: {
        type: [String, Boolean],
        required: false,
        default: false,
    },
    loading: {
        type: Boolean,
        required: false,
        default: false,
    },
    mini: {
        type: [Boolean, String],
        required: false,
        default: false,
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false,
    },
    color: {
        type: String,
        required: false,
        default: 'default',
    },
    class: {
        type: String,
        required: false,
        default: '',
    },
});

const click = (event) => {
    emits('click', event);
};
const buildClass = computed(() => {
    return [
        props.color ? `color-${props.color}` : '',
        props.mini ? 'mini' + (props.mini == 'mini-xs' ? '-xs' : '') : '',
        props.class ? props.class : '',
    ].join(' ');
});
</script>

<style lang="scss" scoped>
button {
    font-size: 14px;
    padding: 0 10px;
    border-radius: 5px;
    display: inline-flex;
    gap: 7px;
    align-items: center;
    transition: 0.15s ease-in-out background, 0.2s ease-in-out color;
    height: 38px;
    margin-right: 1px;
    min-width: fit-content;

    & .mdi {
        font-size: 22px;
        height: 24px;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    & .loading {
        animation: loading 0.4s linear infinite;
        margin-left: -8px;
    }

    &.mini {
        height: 28px;
        // width: 20px;
        min-width: unset;

        & .mdi {
            font-size: 16px;
            height: 20px;
            width: 20px;
        }
    }
    &.mini-xs {
        height: 20px;
        width: 20px;
        min-width: unset;

        & .mdi {
            font-size: 16px;
            height: 16px;
            width: 16px;
        }
    }
    &.text-xs {
        & .mdi {
            font-size: 16px;
        }
    }

    &.color-default {
        background: var(--app-color-default-background);
        color: var(--app-color-default);
        border: 1px solid var(--app-color-default-hover-background);
    }
    &.color-secondary {
        background: var(--app-color-default-background);
        color: var(--app-color-default);
        border: 1px solid var(--app-color-default-hover-background);
    }
    &.color-primary {
        background: var(--app-color-blue-background);
        color: var(--app-color-blue);
        border: 1px solid var(--app-color-blue-hover-background);
    }
    &.color-primary-pressed {
        background: var(--app-color-blue);
        color: var(--app-color-white);
    }
    &.color-transparent {
        background: var(--app-color-transparent-background);
        color: var(--app-color-transparent);
        border: 1px solid var(--app-color-transparent-background);
    }
    &.color-danger {
        background: var(--app-color-danger-background);
        color: var(--app-color-danger);
        border: 1px solid var(--app-color-danger-hover-background);
    }
    &.color-error {
        background: var(--app-color-danger-background);
        color: var(--app-color-danger);
        border: 1px solid var(--app-color-danger-hover-background);
    }
    &.color-success {
        background: var(--app-color-success-background);
        color: var(--app-color-success);
        border: 1px solid var(--app-color-success-hover-background);
    }
    &.color-waiting {
        background: var(--app-color-waiting-background);
        color: var(--app-color-waiting);
        border: 1px solid var(--app-color-waiting-hover-background);
    }
    &:disabled {
        cursor: not-allowed;
        opacity: 0.4;
    }
    &.toggle {
        justify-content: center;
    }
    &.toggle:not(.active) {
        background: var(--app-color-white);
    }
    &.toggle:not(.active):hover { 
        background: var(--app-color-blue);
        color: var(--app-color-white);
    }

    &:not(:disabled, .no-hover, .toggle):hover {
        cursor: pointer;

        &.color-default {
            background: var(--app-color-default-hover-background);
            color: var(--app-color-default-hover);
            border-color: var(--app-color-default-hover-background)
        }
        &.color-secondary {
            background: var(--app-color-default-hover-background);
            color: var(--app-color-default-hover);
            border-color: var(--app-color-default-hover-background)
        }
        &.color-primary {
            background: var(--app-color-blue-hover-background);
            color: var(--app-color-blue-hover);
            border-color: var(--app-color-blue-hover-background)
        }
        &.color-transparent {
            background: var(--app-color-transparent-hover-background);
            color: var(--app-color-transparent-hover);
            border-color: var(--app-color-transparent-hover-background)
        }
        &.color-danger {
            background: var(--app-color-danger-hover-background);
            color: var(--app-color-danger-hover);
            border-color: var(--app-color-danger-hover-background)
        }
        &.color-error {
            background: var(---app-color-danger-hover-background);
            color: var(-app-color-danger-hover);
            border-color: var(--app-color-danger-hover-background)
        }
        &.color-success {
            background: var(--app-color-success-hover-background);
            color: var(--app-color-success-hover);
            border-color: var(--app-color-success-hover-background)
        }
        &.color-waiting {
            background: var(--app-color-waiting);
            color: var(--app-color-waiting-background);
            border-color: var(--app-color-waiting);
        }
    }
}
button.mini-xs > span {
    padding-right: 20px;
}
@keyframes loading {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
</style>
