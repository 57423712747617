<template>
    <label class="i-switch" :class="{disabled: disabled}">
        <input type="checkbox" v-model="val" :disabled="disabled" @input="handleInput" />
        <span class="background"></span>
    </label>
</template>

<script setup>
import { ref, watch } from 'vue';

const props = defineProps({
    modelValue: {
        type: [Boolean, Number],
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});
const emit = defineEmits(['update:modelValue']);

const val = ref(0);

watch(
    () => props.modelValue,
    (nVal) => (val.value = nVal),
    {
        immediate: true,
    },
);

const handleInput = () => emit('update:modelValue', val.value);
</script>

<style lang="scss" scoped>
.i-switch {
    height: 20px;
    width: 40px;
    display: block;
    position: relative;
    cursor: pointer;

    &.disabled {
        opacity: 0.4;
        cursor: not-allowed;
    }
    & .background {
        position: absolute;
        z-index: 0;
        inset: 0;
        background: rgba(0, 0, 0, 0.07);
        border-radius: 40px;
        transition: 0.2s ease-in-out all;
    }

    input {
        appearance: none;
        background: rgba(0, 0, 0, 0.14);
        width: 10px;
        height: 10px;
        border-radius: 50px;
        position: absolute;
        z-index: 1;
        inset: 0;
        margin-top: 5px;
        margin-left: 5px;
        cursor: pointer;
        transition: 0.2s ease-in-out all;

        &:checked {
            background: var(--app-color-white)fff;
            margin-left: 25px;

            & + .background {
                background: var(--app-color-blue);
            }
        }
    }
    &:not(.disabled):hover {
        & .background {
            background: rgba(0, 0, 0, 0.14);
        }
        & input:checked + .background {
            background: darken(#00B8E2, 10);
        }
    }
}
</style>
