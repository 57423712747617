<template>
    <section>
        <div :class="{wrapper: true}">
            <nav class="i-breadcrumbs">
                <div class="item">
                    <Link :href="route(dashboardRoute)">
                        <span class="mdi mdi-home" />
                    </Link>
                </div>
                <span class="mdi mdi-chevron-right"></span>
                <template v-for="(breadcrumb, i) in breadcrumbs" :key="i">
                    <div
                        class="item"
                        :class="{ current: i === breadcrumbs.length - 1 }"
                    >
                        <Link
                            v-if="breadcrumb.route"
                            :href="route(breadcrumb.route, breadcrumb.params)"
                        >
                            {{ breadcrumb.label }}
                        </Link>
                        <span v-else>
                            {{ breadcrumb.label }}
                        </span>
                    </div>
                    <span
                        v-if="breadcrumbs && i < breadcrumbs.length - 1"
                        class="mdi mdi-chevron-right"
                    ></span>
                </template>
            </nav>
            <div>
                <span>{{ auth.user?.fullName }} / {{ auth.user?.company.contact.name }}</span>
            </div>
        </div>
    </section>
</template>

<script setup>
import { computed } from 'vue';
import { usePage } from '@inertiajs/vue3';

defineProps({
    breadcrumbs: {
        type: [Array, Boolean],
        required: false,
        default: false,
    },
});
let auth = usePage().props.auth;

const dashboardRoute = computed(() => {
    const prefix = route().current().split('.')[0];
    if(prefix === 'integeri' || prefix === 'backend') {
        return prefix + '.dashboard.index';
    }
    return 'dashboard.index';
});
</script>

<style lang="scss" scoped>
section {
    background: var(--app-color-white);
    border-bottom: 1px solid rgba(0,0,0,0.07);
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.07);

    & .wrapper {
        gap: 15px;
        grid-template-columns: 1fr auto;
        align-items: center;

        & .i-breadcrumbs {
            padding: 15px 0;
            display: flex;
            gap: 5px;


            & .item {
                color: rgba(0, 0, 0, 0.4);
                border-radius: 4px;
                display: inline-block;

                &.current {
                    color: var(--app-color-blue);
                }
            }
            & .mdi {
                font-size: 22px;
                color: rgba(0, 0, 0, 0.4);
            }
        }
    }
}
</style>
