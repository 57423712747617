<template>
    <IModal :is-active="isActive">
        <div class="i-dirty-form-modal">
            <section class="section">
                <ISectionHeader
                    :title="trans('messages.form.unsaved_changes')"
                />
                <IBox>
                    <p>
                        {{ trans('messages.form.unsaved_changes_message') }}
                    </p>
                </IBox>
            </section>

            <div class="actions">
                <IButton
                    icon="check"
                    :label="trans('global.yes')"
                    color="danger"
                    @click="confirm"
                />
                <IButton
                    icon="close"
                    :label="trans('global.no')"
                    color="success"
                    @click="isActive = false"
                />
            </div>
        </div>
    </IModal>
</template>
<script setup>
import { ref } from 'vue';
import { router } from '@inertiajs/vue3';

const isActive = ref(false);
const routeToVisit = ref(null);


const confirm = () => {
    router.get(
        routeToVisit.value,
        {},
        {
            onBefore: (event) => {
                if (event.detail.visit.method === 'get' && !isActive.value) {
                    isActive.value = true;
                    routeToVisit.value = event.detail.visit.url;
                    return false;
                }
            },
        },
    );
};
</script>

<style lang="scss" scoped>
.i-dirty-form-modal {
    padding: 25px;
    display: grid;
    grid-gap: 25px;

    & p {
        line-height: 1.5em;
    }
    & .section {
        display: grid;
        gap: 10px;
    }
    & .actions {
        display: flex;
        gap: 10px;
        justify-content: flex-end;
    }
}
</style>
