<template>
    <div :class="{app: true, mobile: mq.lgMinus}">
        <IToast />
        <div :class="{'loading': isLoading}">
            <slot />
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { router } from '@inertiajs/vue3';
import IToast from '@/Components/Common/IToast.vue';
import { useMq } from 'vue3-mq';
const mq = useMq();

const isLoading = ref(false);

router.on('start', () => {
    isLoading.value = true;
});

router.on('finish', () => {
    isLoading.value = false;
});
</script>