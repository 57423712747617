import {Head, Link} from '@inertiajs/vue3';
import VGrid from '@revolist/vue3-datagrid';

import IBreadcrumbs from '@/Components/Common/IBreadcrumbs.vue';
import IBox from '@/Components/Common/IBox.vue';
import IButton from '@/Components/Common/IButton.vue';
import IInputField from '@/Components/Common/IInputField.vue';
import ISwitch from '@/Components/Common/ISwitch.vue';
import IModal from '@/Components/Common/IModal.vue';
import ISectionHeader from '@/Components/Common/ISectionHeader.vue';
import IDirtyFormModal from '@/Components/Common/IDirtyFormModal.vue';
import IMultiselect from '@/Components/Common/IMultiselect.vue';

export default {
    install: (app) => {
        app.component('Head', Head);
        app.component('Link', Link);
        app.component('VGrid', VGrid);

        app.component('IBreadcrumbs', IBreadcrumbs);
        app.component('IBox', IBox);
        app.component('IButton', IButton);
        app.component('IInputField', IInputField);
        app.component('ISwitch', ISwitch);
        app.component('IModal', IModal);
        app.component('ISectionHeader', ISectionHeader);
        app.component('IDirtyFormModal', IDirtyFormModal);
        app.component('IMultiselect', IMultiselect);
    },
};
