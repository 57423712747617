<template>
    <Toast>
        <template #message="slotProps">
            <span class="material-icon"></span>
            <div class="p-toast-message-text">
                <span class="p-toast-summary">{{ slotProps.message.summary }}</span>
                <div class="p-toast-detail" v-html="slotProps.message.detail" />
            </div>
        </template>
    </Toast>
</template>

<script setup>
import { router, usePage } from '@inertiajs/vue3';
import { inject, watch } from 'vue';
import { trans } from 'matice';
import Toast from 'primevue/toast';
import { useToast } from 'primevue/usetoast';
import { toastBus } from '@/toast.js';

const toast = useToast();
// to use of toast in non components
toastBus.on('*', (type, args) => {
    if (type === 'add') {
        toast.add(args);
    } else if (type === 'remove') {
        toast.remove(args);
    }
});

const emitter = inject('emitter');

const flashToast = (messages, op = 'add') => {
    if (messages) {
        if (!Array.isArray(messages)) {
            messages = [messages];
        }
        messages.forEach((message) => {
            flashToastOne(message, op);
        });
    }
};
const replaceSpecial = (text) => {
    let matches = text.match(new RegExp('{(mdi-.*?)}'));
    if (matches) {
        text = text.replace(matches[0], '<span class="mdi ' + matches[1] + '" />');
    }
    return text;
};
const flashToastOne = (message, op = 'add') => {
    let mapType = {'success':'success', 'info':'info', 'warn': 'warn', 'error': 'error', 'waiting': 'info'};
    let detail = message.label ?? trans(message.key, {args: message.args});
    if (Array.isArray(detail)) {
        detail = detail.map((text) => {
            return replaceSpecial(text);
        });
    } else {
        detail = replaceSpecial((detail.split('\n')).join('<br>'));
    }
    let args = {
        severity: mapType[message.type??'info'],
        detail: detail,
        summary:  trans('global.' + (message.type ?? 'info')),
    };
    if ((mapType[message.type] == 'error') || message['sticky']) {
        args['sticky'] = true;
    } else {
        args['life'] = message['life']??3000;
    }
    toastBus.emit(op, args);
};
router.on('finish', () => {
    flashToast(usePage().props.flash.message);
});
emitter.on('flashMessage', (message) => {
    flashToast(message);
});
watch(
    () => usePage().props.flash.messageVue,
    (messageVue) => (flashToast(messageVue)),
    {
        immediate: true,
    },
);
</script>
