<template>
    <div :class="'i-inputfield ' + (horizontal ? 'horizontal ' : 'vertical ') + classes">
        <div v-if="label" class="label">
            <div>
                {{ label }}
                <span v-if="icon" :class="'mdi mdi-24 mdi-' + icon"></span>
                <span v-if="tooltip && !tooltip.html" v-tooltip="{value: (tooltip.text??tooltip), escape: true, pt: {text: {style: tooltip.width ? 'width:' + tooltip.width : '', class: 'i-tooltip'}}}" class="i-icon mdi mdi-information-slab-circle-outline"></span>
                <ITooltipHtml
                    v-if="tooltip && tooltip.html"
                    :tooltip="tooltip"
                /> 
                <span v-if="!required && horizontal"><br /></span>
                <span v-if="!required" class="optional">({{ trans('global.input_optional') }})</span>
            </div>
            <slot name="horz_right_caption" />
        </div>
        <div class="input">
            <slot />

            <span v-if="!label"></span>

            <div
                v-if="error && !Array.isArray(error)"
                class="error"
            >
                {{  error }}
            </div>
            <div
                v-if="error && Array.isArray(error)"
                class="error"
                v-html="error.join('<br>')"
            />
            <div
                v-if="hintProcessed.length"
                class="hint"
            >
                <span v-for="hint in hintProcessed" v-bind:key="hint">
                    {{ hint }}<br />
                </span>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import ITooltipHtml from '@/Components/Common/ITooltipHtml.vue';

const props = defineProps({
    label: {
        type: [String, Boolean],
        required: false,
        default: false,
    },
    error: {
        type: [Array, String, Boolean],
        required: false,
        default: false,
    },
    hint: {
        type: [String, Boolean],
        required: false,
        default: false,
    },
    required: {
        type: Boolean,
        required: false,
        default: false,
    },
    horizontal: {
        type: Boolean,
        required: false,
        default: false,
    },
    classes: {
        type: String,
        required: false,
        default: '',
    },
    icon: {
        type: String,
        required: false,
        default: '',
    },
    tooltip: {
        type: [String, Object],
        required: false,
        default: '',
    },
});
const hintProcessed = computed(() => {
    return props.hint ? props.hint.split('\n') ?? [] : [];
});
</script>

<style lang="scss">
.i-inputfield {
    display: grid;
    gap: 5px;

    &.horizontal {
        grid-template-columns: minmax(auto, 1fr) 2fr;
        gap: 15px;
        align-items: baseline;
    }
    &.vertical > .label {
        display: grid;
        grid-template-columns: 1fr 1fr;
        text-wrap: nowrap;
    }
    &.gray .label {
        color: var(--app-color-gray);
    }

    & .label > div {
        white-space: nowrap;
    }

    &.top {
        align-items: flex-start;
    }

    & .optional {
        margin-left: 5px;
        font-size: 10px;
        line-height: 1em;
        display: inline-block;
        transform: translateY(-3px);
        opacity: 0.4;
    }
    & .i-icon {
        font-size: 19px;
        margin-left: 5px;
        height: 24px;
        width: 24px;
        display: inline-block;
    }
    & .input {
        display: grid;
        gap: 5px;

        & input[type='text'],
        & input[type='email'],
        & input[type='number'],
        & input[type='tel'],
        & input[type='password'],
        & select {
            border: 1px solid rgba(0,0,0,0.14);
            border-radius: 4px;
            font-size: 16px;
            color: var(--app-color-default);
            height: 42px;
            padding: 0 10px;

            &:focus {
                border-color: var(--app-color-blue);
                outline: none;
            }
            &:disabled {
                opacity: 0.4;
                cursor: not-allowed;
            }
        }
        & textarea {
            border: 1px solid rgba(0,0,0,0.14);
            border-radius: 4px;
            font-size: 16px;
            color: var(--app-color-default);
            padding: 10px;

            &:focus {
                border-color: var(--app-color-blue);
                outline: none;
            }
            &:disabled {
                opacity: 0.4;
                cursor: not-allowed;
            }
        }

        & input[type='file'] {
            border: 1px solid rgba(0,0,0,0.14);
            border-radius: 4px;
            font-size: 16px;
            color: var(--app-color-default);
            height: 42px;
            padding: 0 10px;
            appearance: none;

            &:empty {
                background: var(--app-color-blue-background);
                border-style: dashed;
            }

            &:focus {
                border-color: var(--app-color-blue);
                outline: none;
            }
            &:disabled {
                opacity: 0.4;
                cursor: not-allowed;
            }
        }

        & input.p-inputtext.p-component {
            width: 100%;
        }

        & .hint {
            font-size: 12px;
            font-weight: 500;
            color: rgba(0,0,0,0.4);
        }
    }
}
</style>
