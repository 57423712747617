import { trans } from 'matice';
import { toastBus } from '@/toast.js';
import moment from 'moment';

export const handleErrors = (e) => {
    let handled = false;
    let data = e.data??{};
    let error = e.message ?? data.message ?? '';
    if (isObject(error)) {
        error = Object.values(error);
    }
    if (Array.isArray(error)) {
        error = error.join('\n');
    }
    let status = e.response ? e.response.status : -1;
    let errorNo = !e.message ? parseInt(error.substring(error.length - 3)) : 'x';
    let detail = error.replace('<br>', '\n');
    if (!isNaN(errorNo)) {
        detail = trans('error.E' + errorNo) + ' ' + detail;
    }
    let response = e.response ? e.response : e;
    let header = '';
    if ((response?.redirected && response.url.includes('login')) || detail.match('Unauthenticated')) {
        detail = trans('auth.nologin');
        setTimeout(() => window.location.href='/', 3500);
        handled = true;
        status = 401;
    } else if ((status != 200) && route().t.url.match(/localhost/)) {
        header = detail + (e.file ? '\n' + e.file + '::' + e.line : '');
        detail = '<span class=\'trace\'><span>';
        let spacer = '&nbsp;';
        let trace = e.trace;
        if (trace && (trace.length > 0)) {
            for (let i = 0; i < 10; i++) {
                detail += spacer.repeat(i * 4) + '-> ' + trace[i]['class'] + trace[i]['type'] + trace[i]['function'] + '()';
                detail += '\n' + spacer.repeat(i * 4) + trace[i]['file'] + '::' + trace[i]['line'] + '\n';
            }
        } else {
            Object.keys(data).forEach((key) => {
                if (key == 'trace') {
                    for (let i = 0; i < 5; i++) {
                        Object.keys(data[key][i]).forEach((subkey, index) => {
                            detail += ((index == 0) ? '&bull;' : '&nbsp;') + '&nbsp;&nbsp;&nbsp;' + subkey + ':' + data[key][i][subkey] + '\n';
                        });
                    }
                } else {
                    detail += key + ': ' + data[key] + '\n';
                }
            });
        }
    }
    if (handled || (status != 200)) {
        toastBus.emit('add', {
            severity: 'error',
            detail: detail.split('\n').join('<br>'),
            summary:  trans('global.error') + ': ' + header,
            sticky: true,
        });
    }
    return handled;
};

export const arrayToLines = (data, prefix = '') => {
    data = data ? (!Array.isArray(data) ? data.split('\n') : data) : [];
    return (data.length ? prefix : '-') + data.join('<br>' + prefix);
};

export const navigateTo = (url) => {
    document.location.href = url;
};

export const formatVideoLengthTime = (node, length) => {
    let getContentLength = (node) => {
        if (node.videoLength) {
            return node.videoLength;
        } else if (node.slideLength) {
            return node.slideLength;
        } else {
            return 0;
        }
    };
    if (node != null) {
        let totalChilds = 0 ;
        let seconds = getContentLength(node);
        if (node.children) {
            node.children.forEach(element => {
                totalChilds += element.totalLength;
            });
        }
        if (node.parent_id == null) {
            return formatTime(node.totalLength);
        } else {
            if (totalChilds > 0) {
                return formatTime(seconds) + (totalChilds ? ' ( + ' + formatTime(totalChilds) + ')' : '');
            } else {
                return formatTime(node.totalLength);
            }
        }
    }
    else {
        return formatTime(length);
    }
};

export const calcVideoLength = (node) => {
    let total = 0;
    if (Array.isArray(node.children)) {
        node.children.forEach((child) => {
            total += calcVideoLength(child);
        });
    }
    let videos = node.videos;
    let slides = node.slides;
    let videoLength = 0, slideLength = 0;
    if (!videos) {
        videos = node.content_node?.videos;
    }
    if (videos && (videos.length > 0)) {
        videos.forEach((video) => {
            videoLength += video.video_s3_length;
        });
    } else {
        if (!slides) {
            slides = node.content_node?.slides;
        }
        if (slides && (slides.length > 0)) {
            slides.forEach((slide) => {
                slideLength += slide.slide_length;
            });
        }
    }
    // from stepData
    if (!videos && !slides) {
        if (node.isVideo) {
            videoLength = node.videosLength;
        } else if (node.isSlide) {
            slideLength = node.slidesLength;
        }
    }
    total += videoLength + slideLength;
    node.videoLength = videoLength;
    node.slideLength = slideLength;
    node.totalLength = total;
    return total;
};

let splitSeconds = (seconds) => {
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor(seconds / 60) % 60;
    let secs = Math.trunc(seconds % 60);
    return [hours, minutes, secs];
};

export const formatVideoDuration = (seconds) => {
    let [hours, minutes, secs] = splitSeconds(seconds);
    return [hours, minutes, secs]
        .map(v => v < 10 ? '0' + v : v)
        .join(':');
};

export const formatTime = (seconds, displayOnEmpty = '') => {
    let negative = seconds < 0 ? '-' : '';
    let [hours, minutes, secs] = splitSeconds(Math.abs(seconds));
    let res = (hours ? hours + 'h ' : '') + (minutes ? minutes + 'min ' : (hours && secs? '0min' : ''));
    let display = (res ? res + ' ' : res) + (secs ? secs + 's' : '');
    return display ? negative + display : displayOnEmpty;
};

export const formatPrice = (value, opt = {}) => {
    return (opt.withEuro ?  '€ ' : '') + Number(value).toFixed(2).replace('.', ',');
};

export const formatPeriod = (period) => {
    let value = parseInt(period);
    period = [...period].reverse().join().substring(0,1);
    let map = {'s': 'seconds', 'i': 'minutes', 'h': 'hours', 'H': 'hours', 'd': 'days', 'w': 'weeks', 'm':'months', 'y':'years'};
    return value + ' ' + trans('global.period.' + map[period]);
};
// options: {linkText: '', target: '_blank', 'linkOnly': false, 'useIcon': false, 'addIcon': false}
// -> PHP version: HelperService::replaceLink
export const linkify = (inputText, options = {}) => {
    let replacedText, replacePattern1, replacePattern2, replacePattern3;
    let iconSpan = '<span class="mdi mdi-open-in-new" />';
    let linkText = (typeof(options.linkText)==='undefined')?'':options.linkText;
    if (options.useIcon) {
        linkText = iconSpan;
    }
    let target = options.target?options.target:'_blank';

    if (inputText == null) {
        return null;
    }
    //URLs starting with http://, https://, or ftp://
    replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim;
    replacedText = inputText.replace(replacePattern1,
        options.linkOnly ? '$1' : 
            (options.useIcon ? '$1 <a href="$1" target="'+target+'">'+linkText+'</a>' : 
                '<a href="$1" target="'+target+'">'+((linkText=='')?'$1':linkText)+(options.addIcon?' ' + iconSpan:'')+'</a>'));

    //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
    replacePattern2 = /(^|[^/])(www\.[\S]+(\b|$))/gim;
    replacedText = replacedText.replace(replacePattern2,
        options.linkOnly ? 'https://$2' : 
            (options.useIcon ? '$1$2 <a href="https://$2" target="'+target+'">'+linkText+'</a>' : 
                '$1<a href="https://$2" target="'+target+'">'+((linkText=='')?'$2':linkText)+(options.addIcon?' ' + iconSpan:'')+'</a>'));

    //Change email addresses to mailto:: links.
    replacePattern3 = /(([a-zA-Z0-9\-_.])+@[a-zA-Z_]+?(\.[a-zA-Z]{2,6})+)/gim;
    replacedText = replacedText.replace(replacePattern3,
        options.linkOnly ? '$1' : 
            (options.useIcon ? '$1 <a href="mailto:$1">'+linkText+'</a>' : 
                '<a href="mailto:$1">$1'+(options.addIcon?' ' + iconSpan:'')+'</a>'));
    
    return replacedText;
};

export const debounce = (fn, wait) => {
    let timer;
    return function(...args){
        if(timer) {
            clearTimeout(timer);
        }
        const context = this;
        timer = setTimeout(() => { fn.apply(context, args); }, wait);
    };
};

export const throttle = (fn, wait) => {
    let throttled = false;
    return function(...args){
        if(!throttled){
            fn.apply(this, args);
            throttled = true;
            setTimeout(() => {
                throttled = false;
            }, wait);
        }
    };
};

export const arrayDiffByKey = (key, ...arrays) => {
    return [].concat(...arrays.map( (arr, i) => {
        const others = arrays.slice(0);
        others.splice(i, 1);
        const unique = [...new Set([].concat(...others))];
        return arr.filter( x =>
            !unique.some(y => x[key] === y[key]),
        );
    }));
};

function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}
  
export function mergeDeep(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) {
                    Object.assign(target, { [key]: {} });
                } else {
                    target[key] = Object.assign({}, target[key]);
                }
                mergeDeep(target[key], source[key]);
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        }
    }
    return mergeDeep(target, ...sources);
}
  
/**
 * formats pretty file size 8kB, 1.2MB, ..
 * if format is given as: Bytes, kB, MB, GB it will display the value in this specific unit
 * eg: formatFileSize(239748632746, 'MB') -> 228642,1 MB
 * @param integer size 
 * @param string format 
 * @returns 
 */
export const formatFileSize = (size, format = '') => {
    let unit = ['bytes', 'kB', 'MB', 'GB', 'TB'];
    let count = 0;
    while ((size / 1024 > 1) && ((format === '') || (format != unit[count]))) {
        size = size / 1024;
        count++;
    }
    return size.toFixed(1) + ' ' + unit[count];
};

export const pluck = (arr, ...keys) => {
    return keys.length > 1 ?
        arr.map(i => keys.map(k => i[k])) :
        arr.map(i => i[keys[0]]);
};

export const pluckFlat = (arrayOfObj, company_id = 0) => {
    let result = [];
    arrayOfObj.map((obj) => {
        if (obj && (!company_id || (((obj['company_id']??0) == company_id)))) {
            if (obj['content_node']??null) {
                result.push(obj['content_node']['id']);
            } else if (obj['content_node_id']??0) {
                result.push(obj['content_node_id']);
            }
            if (obj.children && obj.children.length) {
                result = result.concat(pluckFlat(obj.children));
            }
        }
    });
    return result;
};

export const pluckFlatContentNodeForVideo = (arrayOfObj, company_id = 0) => {
    let result = [];
    arrayOfObj.map((obj) => {
        if (obj) {
            if (!parseInt(company_id) || (obj['content_node'] && ((parseInt(obj['content_node']['company_id']??0)) == company_id))) {
                if (obj['content_node']??null) {
                    if (obj['content_node']['videos']?.length??0) {
                        result.push(obj['content_node']['id']);
                    }
                } else if (obj['content_node_id']??0) {
                    result.push(obj['content_node_id']);
                }
            }
            if (obj.children && obj.children.length) {
                result = result.concat(pluckFlatContentNodeForVideo(obj.children, company_id));
            }
        }
    });
    return result;
};

export const checkCompanyLocationAccess = (auth, location) => {
    if (auth.roles.includes('integeri.admin') || auth.roles.includes('company.admin')) {
        return true;
    }
    let found = false;
    if (auth.roles.includes('company.location.admin')) {
        found = (auth.user.locationAdmins.indexOf(location['id']) != -1);
    }
    if (auth.roles.includes('company.team.admin')) {
        auth.user.teamAdmins.forEach(function(element) {
            found |= (element['location_id'] == location['id']);
        });
    }
    return found;
};

export const checkCompanyTeamAccess = (auth, team) => {
    if (auth.roles.includes('integeri.admin') || auth.roles.includes('company.admin')) {
        return true;
    }
    let found = false;
    if (auth.roles.includes('company.location.admin')) {
        found |= (auth.user.locationAdmins.indexOf(team['location_id']) != -1);
    }
    if (auth.roles.includes('company.team.admin')) {
        auth.user.teamAdmins.forEach(function(element) {
            found |= (element['team_id'] == team['id']);
        });
    }
    return found;
};

export const getMimeType = async (url, then) => {
    await fetch(url, {method: 'HEAD', mode: 'no-cors'}).then((res) => {
        let type = res.headers.get('Content-Type');
        if (then) then(type);
    }).catch((error) => {
        if (then) then('error ' + error.message);
    });
};

export const isImageUrl = async (url, allowedTypes = [], then = null) => {
    await getMimeType(url, function(type) {
        if (allowedTypes.length) {
            type = allowedTypes.includes(type) ? true : type;
        } else {
            type = type.startsWith('image') ? true : type;
        }
        if (then) {
            then(type);
        }
    });
};

export const isVideoUrl = async (url, allowedTypes = [], then = null) => {
    await getMimeType(url, function(type) {
        if (allowedTypes.length) {
            type = allowedTypes.includes(type) ? true : type;
        } else {
            type = type.startsWith('video') ? true : type;
        }
        if (then) {
            then(type);
        }
    });
};

export const evalCalendarPeriod = (period, period_op) => {
    let [since, until, op, date] = [null, null, null, null];
    // passthrough from url
    if (typeof period == 'string') {
        let a = period.split('.');
        date = moment(Date.parse(a[2]+'-'+a[1]+'-'+a[0])).format('yy-MM-DD');
        op = period_op??'exact';
    }
    // single from calendar
    if (period instanceof Date) {
        date = moment(period).format('yy-MM-DD');
        op = period_op??'exact';
    }
    // range from calendar
    if (period instanceof Array) {
        since = period[0] ? moment(period[0]).format('yy-MM-DD') : null;
        until = period[1] ? moment(period[1]).format('yy-MM-DD') : null;
        op = period_op??'exact';
    }
    return [since, until, op, date];
};

export const dummyAvatarSrc = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdj4OlY9h8ABBgCOpbRy0cAAAAASUVORK5CYII=';
export const mapLanguageFlags = (lang) => {
    let list = {'zh':'cn', 'cs':'cz', 'da':'dk', 'et':'ee', 'en':'gb', 'el':'gr', 'ja':'jp', 'ko':'kr', 'nb':'no', 'sv':'se', 'sl':'si', 'ar':'sy', 'uk':'ua'};
    return list[lang] ?? lang ?? 'de';
};

