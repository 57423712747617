<template>
    <slot name="default"></slot>
    <span @mouseover="toggle" @mouseleave="toggle" :class="buildClass('icon')"></span>
    <OverlayPanel :style="tooltip.width ? 'width:' + tooltip.width : ''" :class="buildClass('overlay')" ref="tooltipoverlay" appendTo="body">
        <span v-html=tooltip.html></span>
    </OverlayPanel>
</template>

<script setup>
import { ref } from 'vue';
import OverlayPanel from 'primevue/overlaypanel';
const tooltipoverlay = ref(false);
const toggle = (event) => {
    tooltipoverlay.value.toggle(event);
};

const props = defineProps({
    tooltip: {
        type: Object,
        required: true,
        default: () => ({html: 'x', width: ''}),
    },
    icon: {
        type: String,
        required: false,
        default: 'information-slab-circle-outline',
    },
    class: {
        type: [String, Array],
        required: false,
        default: 'i-tooltip',
    },
});
const buildClass = (type) => {
    if (type == 'icon') {
        return [
            'i-icon mdi mdi-24px mdi-' + props.icon,
            props.tooltip.class ? (Array.isArray(props.tooltip.class) ? props.tooltip.join(' ') : props.tooltip.class) : '',
        ].join(' ');
    } else {
        return [
            props.class ? (Array.isArray(props.class) ? props.class.join(' ') : props.class) : '',
        ].join(' ');
    }
};

</script>
<style scoped>
.i-icon {
    padding-left: 3px;
}
</style>